import { mapActions, mapGetters } from 'vuex';
export default {
    methods: {
        ...mapActions(['logoutUser']),
        //get dataCity
        async getDataCity() {
            await this.$axios.get('/common/getListProvince')
            .then(res => {
                this.dataCity = res.data.province
            }).catch(err => {
                this.errorAPI(err)
            })
        },

        async getListUnit() {
            await this.$axios.get('/real_estate/getListUnit')
                .then(res => {
                    this.listUnit = res.data
                }).catch(err => {
                    this.errorAPI(err)
                })
        },

        async getListJuridicalByCategory(category_id) {
            this.startLoading()
            await this.$axios.get('/real_estate/getListJuridicalByCategory', {
                params: {
                    category_id: category_id
                }
            })
                .then(res => {
                    this.listJuridical = res.data
                    this.endLoading()
                }).catch(err => {
                    this.errorAPI(err)
                    this.endLoading()
                })
        },
        //CALL API DISTRICT
        async callApiDistrict(id) {
            const res = await this.$axios.get('/common/getDistrictByProvince?province_id=' + id)
            if (res.status == 200) {
                return res.data.district
            }
        },

        //CALL API WARD
        async callApiWard(id) {
            const res = await this.$axios.get('/common/getWardByDistrict?district_id=' + id)
            if (res.status == 200) {
                return res.data.ward
            }
        },
        async getListBank() {
            await this.$axios.get('/common/getListBank')
                .then(res => {
                    this.listBank = res.data.bank
                }).catch(err => {
                    this.errorAPI(err)
                })
        },

        async _uploadFile(event) {
            this.startLoading()
            let name = event.target.name
            let files = Array.from(event.target.files)
            if (name == 'file_path') {
                this.formData[name] = []
                files.forEach((e, index) => {
                    this.$axios.post('/common/uploadFile', {
                        file: e,
                        path: 'member/file/',
                    }, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then((res) => {
                        this.formData[name].push(res.data.list_result[0].path)
                        this.endLoading()
                    }).catch(err => {
                        this.errorAPI(err)
                        this.endLoading()
                    })
                });
            } else {
                files.forEach((e, index) => {
                    this.$axios.post('/common/uploadFile', {
                        file: e,
                        path: 'member/' + name + '/',
                    }, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then((res) => {
                        if (event.target.accept == 'image/png, image/gif, image/jpeg, image/jpg') {
                            this.$refs[name].src = this.$storage + '/' + res.data.list_result[0].path_tam
                        }
                        this.formData[name] = res.data.list_result[0].path
                        this.endLoading()
                    }).catch(err => {
                        this.errorAPI(err)
                        this.endLoading()
                    })
                });
            }

        },

        async uploadFile(path, files) {
            this.startLoading();
            const res = await this.$axios.post('/common/uploadFile', {
                file: files,
                path: path,
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            if(res.status == 200) {
                this.endLoading();
                return res.data.list_result[0]
            } else {
                this.endLoading();
                this.$notify({ message: 'Vui lòng thử lại!', type: 'error', customClass: 'error', });
                return true
            }
        },
        //FormatNumber
        formatNumber(value) {
            return value.replace(/[^0-9]/g, "")
        },
        adjustTextareaHeight(event) {
            const textarea = event.target;
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 'px';
        },
        calculatedHeight(refName) {
            if (this.$refs[refName]) {
                return this.$refs[refName].scrollHeight + 'px'
            }
        },
        inputPrice() {
            let name = event.target.name
            const cleanedValue = this.formData[name].replace(/[^\d]/g, '');
            let number = parseInt(cleanedValue, 10);
            this.formData[name] = number.toLocaleString('vi');
            if (cleanedValue == "NaN" || cleanedValue == '') {
                this.formData[name] = ''
            }
        },
        inputNumber() {
            let name = event.target.name
            this.formData[name] = event.target.value.replace(/[^0-9.]/g, "")
        },
        //Show Password 
        togglePassword(value, input) {
            this[value] = !this[value]
            if (this.$refs[input].type == "password") {
                this.$refs[input].type = "text";
            } else {
                this.$refs[input].type = "password";
            }
        },

        // Show auction time left
        startCountdown(duration) {
            let endTime = this.$moment.utc().valueOf() + duration - 25200000;
            let interval = setInterval(() => {
                let timeLeft = Math.round((endTime - this.$moment.utc().valueOf()));
                if (timeLeft < 0) {
                    clearInterval(interval);
                    timeLeft = 0;
                }
                this.timeLeft = timeLeft;
            });
        },

        formatTime(time, type) {
            let hours = Math.floor(time / (1000 * 60 * 60));
            let minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((time % (1000 * 60)) / (1000));
            if (type == 'hour') {
                return (
                    (hours < 10 ? '0' + hours : hours) + ' giờ       ' +
                    (minutes < 10 ? '0' + minutes : minutes) + ' phút       ' +
                    (seconds < 10 ? '0' + seconds : seconds) + ' giây '
                );
            } else {
                return (
                    (hours < 10 ? '0' + hours : hours) + ':' +
                    (minutes < 10 ? '0' + minutes : minutes) + ':' +
                    (seconds < 10 ? '0' + seconds : seconds)
                );
            }
        },
        //CHANGE PATH
        changePath(name, title, path) {
            window.history.pushState(name, title, path);
        },

        formateTime(time, format) {
            if (!format) {
                return new Date(time).toLocaleString('en-GB', { timeZone: 'UTC' }).replace(',', "")
            } else {
                return this.$moment.utc(time).format(format)
            }
        },
        formateDate(time) {
            return this.$moment.utc(time).format("DD/MM/YYYY")
        },
        handleError() {
            event.target.src = require('@/assets/images/th.jpg');
        },
        handleErrorAvatar() {
            event.target.src = require('@/assets/images/blank-avatar-icon-26.png');
        },
        errImgApi(url) {
            event.target.src = require(`@/assets/images/${url}`);
        },
        encode(id) {
            return btoa(id);
        },

        decode(id) {
            return parseInt(atob(id));
        },

        reloadPage() {
            window.location.reload()
        },
        formatCount(count) {
            if (count > 1000) {
                return (count / 1000).toFixed(1) + "k"
            } else {
                return count
            }
        },
        formatpreNumber(number) {
            if (number < 10) {
                return '0' + number
            } else {
                return number
            }
        },
        formatPrice(number) {
            if (!number) {
                return ''
            }
            else if (number >= 1000000000) {
                const billion = number / 1000000000;
                return Math.round((billion + Number.EPSILON) * 1000) / 1000 + ' tỷ';
            } else if (number >= 1000000) {
                const million = number / 1000000;
                return Math.round((million + Number.EPSILON) * 1000) / 1000 + ' triệu';
            } else {
                return number.toString();
            }
        },
        getUnitById(realEstateDetail) {
            if (realEstateDetail.unit_id == 2) {
                let result = this.formatPrice((parseInt(realEstateDetail.price.replace(/[^\d]/g, '')) / realEstateDetail.acreage).toFixed(1))
                return '~' + result + '/m2'
            } else if (realEstateDetail.unit_id == 4) {
                return "VNĐ/m2"
            } else if (realEstateDetail.unit_id == 6) {
                return "VNĐ/tháng"
            }
        },
        errorAPI(err) {
            if (err.response) {
                if (err.response.status == 401 || err.response.status == 409) {
                    this.$notify({ message: 'Hết phiên đăng nhập', type: 'error', customClass: 'error', });
                    this.logoutUser()
                    this.$router.push('/')
                }
                this.$notify({ message: err.response.data.error, type: 'error', customClass: 'error', });
            } else {
                this.$notify({ message: 'Lỗi hệ thống!', type: 'error', customClass: 'error', });
            }
        },
        startLoading() {
            this.loading = this.$loading({
                lock: true,
                text: "Loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
        },

        endLoading() {
            if (!this.isEmptyCustom(this.loading)) {
                this.loading.close();
            }
        },
        getUrlVideo(video) {
            if(video){
                if (video.includes('https://youtu.be/')) {
                    const parts = video.split("https://youtu.be/");
                    if (parts.length > 1) {
                        return parts[1];
                    }
                } else if(video.includes('/shorts')) {
                   return video.split("/shorts/")[1]
                } else {
                    const equalSignIndex = video.indexOf("?v=");
                    if (equalSignIndex !== -1) {
                        return video.substring(equalSignIndex + 3);
                    }
                }
            }
        },
        isYouTubeLink(url) {
            // Biểu thức chính quy để kiểm tra đường dẫn YouTube
            const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+/;

            return youtubeRegex.test(url);
        },
        isEmptyCustom(value) {
            // Array
            if (Array.isArray(value)) {
                // eslint-disable-next-line eqeqeq
                return value.length == 0;
            }

            // Object
            if (
                value &&
                typeof value === "object" &&
                value.constructor === Object
            ) {
                // eslint-disable-next-line eqeqeq
                return Object.keys(value).length == 0;
            }

            // string
            if (typeof value === "string" || value instanceof String) {
                // eslint-disable-next-line eqeqeq
                return value.trim().length == 0;
            }

            // Null & undefined
            if (value === null || typeof value === "undefined") {
                return true;
            }

            // Number
            if (value === 0) {
                return true;
            }
            return false;
        },
        async copy(txt) {
            try {
                await navigator.clipboard.writeText(txt);
                this.$notify({
                    message: 'Sao chép thành công',
                    type: 'success',
                    customClass: 'success',
                });
            } catch ($e) {
                this.$notify({
                    message: 'Sao chép không thành công',
                    type: 'error',
                    customClass: 'error',
                });
            }
        },
        convertToSlug(text) {
            text = text.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
            text = text.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
            text = text.replace(/ì|í|ị|ỉ|ĩ/g, "i");
            text = text.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
            text = text.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
            text = text.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
            text = text.replace(/đ/g, "d");
            text = text.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
            text = text.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
            text = text.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
            text = text.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
            text = text.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
            text = text.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
            text = text.replace(/Đ/g, "D");
            // Some system encode vietnamese combining accent as individual utf-8 characters
            // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
            text = text.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
            text = text.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
            // Remove extra spaces
            // Bỏ các khoảng trắng liền nhau
            text = text.replace(/ + /g, " ");
            text = text.trim();
            // Remove punctuations
            // Bỏ dấu câu, kí tự đặc biệt
            text = text.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
            return text
                .toLowerCase()
                .replace(/[^\w\s-]/g, '')
                .replace(/\s+/g, '-')
                .replace(/^-+|-+$/g, '');
        },
        async getInformation() {
            const res =  await this.$axios.get('/system/getInformation')
            if (res.status == 200) {
                return res
            }
        },
        async getHotlineNumber() {
            await this.$axios.get('/system/getInformation')
            .then(res => {
                this.hotline = res.data.general.hotline
            }).catch(err => {
                this.errorAPI(err)
            })
        },
        async getEmailSettings() {
            await this.$axios.get('/system/getInformation')
            .then(res => {
                this.mail_support = res.data.general.mail_support
                
            }).catch(err => {
                this.errorAPI(err)
            })
        },
        getIdFromSlug(slug, index) {
            let arr = slug.split("-")
            return arr[arr.length - index];
        },
        validateYouTubeUrl(url) {
            const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+/;
            if(url) {
                return youtubeRegex.test(url);
            } else {
                return true
            }
        },
        async getListProject(){
            const res =  await this.$axios.get('/project/getListProjectForMember', {
                params: {
                    order: 'latest',
                    page: 1,
                    per_page: 999,
                    name: "",
                }
            })
            if (res.status == 200) {
                return res
            }
        },
        getCookie(cname) {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for(var i = 0; i <ca.length; i++) {
              var c = ca[i];
              while (c.charAt(0) == ' ') {
                c = c.substring(1);
              }
              if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
              }
            }
            return "";
          }
    },
};
